body {
   
font-family: 'Comic Sans MS';
letter-spacing: 0.05rem;
}
h1 {
    
    line-height: 1,5em;
   text-shadow: 1px 1px 2px rgb(7, 7, 7);
}


h4, h5, h6 {
  font-family: 'Comic Sans MS';
  
    font-weight: lighter;
}

p {
  font-family: 'Open Sans', sans-serif;
    font-style: italic;
}
 .btn {
   background-color: green;
   color: whitesmoke;
 }
 .btn:hover {
  background-color: #2f4308;
}

#logo {
  width: 30px;
  margin-right: 15px;
}



 /* section unde si cand*/
#geolocal {
  background-image: linear-gradient(90deg, #BDE07E , #CDF6FC 95%);
}
#geolocal h1 {
  color: green
}

  /* section unde si cand*/



  /* css blog*/

.main-row {
 
    margin-bottom: 8%;
    background-color:rgba(255, 255, 255, 0.705);
    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    border-radius: 0.5rem;
    
  }
  
  .blog-img > img {
   width: 250px;
   transform: translateY(-30px);
   border-radius: 50%;
   box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
  }
  .blog-title > h3 {
    line-height: 2rem;
  }
  @media (min-width: 500px) {
  .blog-title > h3 {
    line-height: 3rem;
  }
 
  }
  @media (min-width: 1100px) {
    .main-row {
      max-width: 800px;
      position: relative;
      left: 15%;
    }
    }

  #gol {
    height: 300px;
  }
    